@font-face {
  font-family: didot;
  src:  url('../fonts/Didot.woff2') format('woff2'),
        url('../fonts/Didot.woff') format('woff');
}

@font-face {
  font-family: didot-bold;
  src:  url('../fonts/Didot-Bold.woff2') format('woff2'),
        url('../fonts/Didot-Bold.woff') format('woff');
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    box-sizing:         border-box;
    outline: none;
}

h1,h2,h3,h4,h5,p {
    margin: 0;
    font-weight: normal;
}

html, body {
    overflow-x: hidden;
    position: relative
}

html {
    width: 100%;
    font-size: 15px;
}

body {
    width: 100%;
	padding: 0;
  	margin: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    background-color: #fff;
    color: #3C3C3B;
    height: 100vh;
    overflow: hidden;
}

h1 {
    font-size: 2.1rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.2rem;
}

p {
    line-height: 1.3;
    letter-spacing: 1.2px;
    font-size: 1rem;
}

input, select, textarea, label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    letter-spacing: 1.5px;
}

input, select, textarea {
    font-size: 1rem;
}    

textarea {
    resize: none;
}

::-webkit-input-placeholder { 
    color: rgba(60, 60, 59, 0.5);
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

:-ms-input-placeholder {
    color: rgba(60, 60, 59, 0.5);
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

::placeholder {
    color: rgba(60, 60, 59, 0.5);
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

img {
    width: 100%;
    vertical-align: middle;	
}

a {
    text-decoration: none; 
    color: #050d9e; 
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pointer {
    cursor: pointer;
}

.both-side-padding {
    padding-left: 10vw;
    padding-right: 10vw;
}

.left-side-padding {
    padding-left: 10vw;
}

.right-side-padding {
    padding-right: 10vw;
}

.right-side-absolute {
    right: 10vw;
}

.vertical-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-text p {
    writing-mode: vertical-lr; 
    -ms-writing-mode: vertical-lr;
    -webkit-writing-mode: vertical-lr; 
    transform: rotate(180deg);
    -ms-transform: rotate(180deg); 
    -webkit-transform: rotate(180deg); 
    -moz-transform: rotate(180deg);
    display: inline-block;
}

.form-container .input-container {
    width: 100%;
    margin-top: 20px;
    position: relative;
}

.form-container .input-div {
    position: relative;
}

.form-container input {
    border: 1px solid rgba(60, 60, 59, 0.2);
    width: 100%;
    padding-left: 15px;
    height: 50px;
}

.form-container textarea {
    border: 1px solid rgba(60, 60, 59, 0.2);
    width: 100%;
    padding-left: 15px;
    padding-top: 20px;
    height: 150px;
}

.checkbox-full-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.checkbox-full-container p.text {
    margin-left: 10px;
    font-size: 0.9rem;
    color: rgba(60, 60, 59, 0.5);
}

.checkbox-full-container p.text span,
.checkbox-full-container p.text a {
    text-decoration: none;
    color: rgba(60, 60, 59, 0.5);
    border-bottom: 1px solid rgba(60, 60, 59, 0.5);
    cursor: pointer;
}

.checkbox-full-container label.checkbox-container {
    position: relative;
}

.checkbox-full-container label.checkbox-container .checkbox {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.checkbox-full-container label.checkbox-container .custom-checkbox {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
}

.checkbox-full-container label.checkbox-container .custom-checkbox .new-container {
    width: 25px;
    height: 25px;
    border: 1px solid rgba(60, 60, 59, 0.2);
    display: block;
    position: relative;
    flex-basis: 25px;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    background-color: transparent;
}

.checkbox-full-container label.checkbox-container .checkbox:checked + .custom-checkbox .new-container:after {
    width: 5px;
    height: 15px;
    border: solid #3C3C3B;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    left: 9px;
    top: 2px;
}

.checkbox-full-container .input-field.input-checkbox {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
}

.input-error {
    position: absolute;
    background: #fff;
    border: 1px solid #E10613;
    padding: 7px;
    border-radius: 5px;
    min-width: 70px;
    left: 20px;
    display: none;
    z-index: 1;
    bottom: -25px;
}

.input-error.bottom-space {
    bottom: -40px;
}

.input-error.show {
    display: block;
}

.input-error:before,
.input-error:after
{
    left: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    top: -21px;
}

.input-error:before
{
    border-bottom-color: #E10613;
    border-width: 11px;
    top: -22px;
}

.input-error:after
{
    border-bottom-color: #fff;
    border-width: 11px;
}

.input-error p {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 400;
    color: #E10613;
}

.form-container .error-active {
    border: 1px solid #E10613 !important;
    color: #E10613 !important;
}

.form-container .input-error-container {
    margin-top: 5px;

}

.form-container .input-error-container p {
    font-size: 0.9rem;
    color: #E10613;
}

.default-title-container {
    width: 100%;
    text-align: center;
}

.default-title-container h1 {
    font-family: didot;
    font-size: 3.1rem;
}

.default-title-container h1 span {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    
}

.default-title-container p {
    margin-top: 10px;
}

.signature-dots {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #3C3C3B;
    margin-left: 10px;
    margin-right: 10px;
}

.logo-first-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
    max-width: 1600px;
}

.logo-container {
    position: absolute;
    top: 0;
    left: 3%;
    width: 30%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container img {
    width: 50%;
}





