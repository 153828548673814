.main-container {
	position: relative;
	min-height: 100vh;
    width: 100%;
    background-image: url('../img/main-image.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-attachment: fixed;
    visibility: hidden;
    opacity: 0;
}

.main-container.active {
	visibility: visible;
    opacity: 1;
}

.main-background-opacity {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(29, 29, 27, 0.25);
}

.main-container h1 {
	color: #fff;
	font-size: 4rem;
	font-family: didot;
	line-height: 1.1;
	position: relative;
}

.main-container h1 span {
	font-weight: 700;
	font-size: 3.6rem;
	font-family: 'Raleway', sans-serif;
}

.main-container h1 span br {
	display: none;
}

.main-button {
	margin-top: 35px;
    background-color: #fff;
    padding: 12px 25px;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
}

.main-button p {
   color: #1D509B;
   font-weight: 700;
   font-size: 1.1rem;
}

.main-art-top-container {
	position: relative;
    margin-top: -17%;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
}

.main-art-top-container.active {
	visibility: visible;
    opacity: 1;
}

.home-social-container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.home-social-container p {
	color: #fff;
	font-size: 0.9rem;
	margin-top: 5px;
	margin-bottom: 5px;
}

.home-social-container img {
	width: 25px;
	margin-top: 5px;
}

.home-social-container .border-white {
	height: 40px;
	width: 0.5px;
	background-color: #fff;
}

.signature-container {
	position: absolute;
    z-index: 999999;
    width: 100%;
    width: 100%;
    height: 38%;
    top: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signature-container p {
	font-size: 0.95rem;
}

.signature-container p a {
	text-decoration: none;
	color: #3C3C3B;
}

.signature-container p br {
	display: none;
}

.social-container-mobile {
	display: none;
}

