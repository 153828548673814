.banner-container {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 650px;
    height: 60vh;
    width: 100%;
    background-attachment: fixed;
    position: relative;
}

.banner-background-opacity {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(29, 29, 27, 0.25);
}

.banner-art-top-container {
	position: relative;
	margin-top: -15%;
}

.banner-next-container-fix {
	position: relative;
	margin-top: -4%;
}