.modal-background-container {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99999999999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    overflow: hidden;
}

.modal-background-container.active {
	opacity: 1;
	visibility: visible;
}

.modal-container {
	width: 50%;
	max-width: 600px;
    background-color: #fff;
    transform: translateX(100%);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 75px 0px;
}

.form-padding {
    padding: 0px 75px;
}

.modal-container.active {
	transform: translateX(0);
}

.modal-container .title-container {
    padding: 0px 75px;
    margin-bottom: 40px;
}

.modal-container .title-container h3 {
    font-family: didot-bold;
}

.submit-container {
    margin-top: 60px;
    display: flex;
    align-items: center;
}

.submit-container .gray-art {
    height: 50px;
    background-color: #ECECEC;
    width: 40%;
}

.submit-container input {
     background-image: linear-gradient(to right, #187DBE, #1D509B);
     color: #fff;
     border: none !important;
     cursor: pointer;
     padding-left: 0 !important;
     padding-right: 0 !important;
     width: 60% !important;
     height: 70px !important;
}

.modal-container .close-container {
    position: absolute;
    top: 40px;
    right: 75px;
}

.modal-container .close-container img {
    width: 22px;
    cursor: pointer;
}

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 80px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-msg-background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success-msg-container {
     width: 650px;
    background-color: #fff;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
    cursor: pointer;
}

.success-msg-container h3 {
    font-family: didot-bold;
    text-align: center;
    font-size: 2.1rem;
    line-height: 1.2;
}

.success-msg-container p {
    margin-top: 15px;
    text-align: center;
}




