@media screen and (max-width: 1300px) 
{
	body {
		min-height: 100vh;
		height: auto;
	}

	.main-container {
	    min-height: 650px;
	    height: 100vh;
	}

	.signature-container {
    	height: 43%;
	}

	.modal-container {
		overflow-y: scroll;
		height: 100%;
		justify-content: flex-start;
	}

	.banner-container {
	    min-height: 550px;
	}
	

}

@media screen and (max-width: 1150px) {

	html {
		font-size: 16px;
	}

	.logo-container {
    	left: 6%;
    	width: 49%;
    	height: 85%;
    }

    .logo-container img {
    	min-width: 110px;
    }

    .home-social-container {
    	display: none;
    }

    .main-container h1 span br {
		display: block;
	}

	.social-container-mobile {
		margin-top: 45px;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
	}

	.social-container-mobile img {
		width: 40px;
	}

	.social-container-mobile img:first-child {
		margin-right: 10px;
	}

	.main-art-top-container {
    	margin-top: -25%;
    	padding-bottom: 5%;
    }

    .signature-container {
    	height: 66%;
    	top: 34%;
	}

	.signature-container {
		text-align: center;
	}

	.signature-container p br {
		display: block;
	}

	.modal-background-container {
    	position: relative;
    	top: unset;
    	left: unset;
    	overflow: unset;
    	min-height: 100vh;
    	background-color: #fff;
    }

	.modal-container {
	    width: 100%;
	    max-width: 100%;
	}

	.form-padding {
	    padding: 0px 10vw;
	}

	.modal-container .title-container {
	    padding: 0px 10vw;
	}

	.modal-container .close-container {
	    right: 10vw;
	}

	.success-msg-background-container,
	.loader-container {
		position: fixed;
    	z-index: 99999999999;
    	background-color: rgba(0, 0, 0, 0.3);
	}

	.signature-container {
		flex-direction: column;
	}

	.signature-dots {
		display: none;
	}

	.signature-container p,
	.privacy-policy-footer p {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 1rem;
	}

	.privacy-policy-footer {
		flex-direction: column;
	}

	.default-title-container h1 {
    	font-size: 2.8rem;
	}

	.banner-container {
	    min-height: 50vh;
	    background-attachment: unset;
	}

	.main-container {
		background-attachment: unset;
	}

	.default-title-container {
    	text-align: left;
	}

	.banner-next-container-fix {
    	margin-top: -18%;
    }

    .back-icon {
		font-size: 1.3rem;
	}

	.back-container p {
		font-size: 1rem;
	}

	.submit-container {
    	margin-top: 80px;
	}


}

@media screen and (max-width: 800px) 
{
	.banner-next-container-fix {
    	margin-top: -17%;
    }

     .back-icon {
		font-size: 1.2rem;
	}

	.back-container p {
		font-size: 0.95rem;
	}


}


@media screen and (max-width: 500px) 
{
	html {
		font-size: 15px;
	}

	.both-side-padding {
	    padding-left: 25px;
	    padding-right: 25px;
	}

	.left-side-padding {
	    padding-left: 25px;
	}

	.right-side-padding {
	    padding-right: 25px;
	}

	.main-container h1 {
		font-size: 3.6rem;
		text-align: center;
	}	

	.main-container h1 span {
		font-size: 3.2rem;
	}

	.social-container-mobile {
		margin-top: 35px;
	}

	.social-container-mobile img {
		width: 30px;
	}

	.main-art-top-container {
    	padding-bottom: 15%;
    }

    .signature-container {
    	height: 71%;
    	top: 30%;
	}

	.signature-container p,
	.privacy-policy-footer p {
		margin-top: 5px;
		margin-bottom: 5px;
		font-size: 0.95rem;
	}

	.default-title-container h1 {
    	font-size: 2.4rem;
	}

	.privacy-policy-container {
    	margin-top: 50px;
	}

	input, select, textarea {
    	font-size: 16px;
	}   

	.privacy-policy-item-container {
    	margin-top: 30px;
	}

	.privacy-policy-footer {
	    margin-top: 50px;
	    margin-bottom: 25px;
	}

	.modal-container {
	    padding: 40px 0px;
	}

	.form-padding {
	    padding: 0px 25px;
	}

	.modal-container .title-container {
	    padding: 0px 25px;
	    margin-bottom: 10px;
	}

	.modal-container .close-container {
	    top: 10px;
	    right: unset;
	    left: 25px;
	}

	.success-msg-container {
		width: calc(100% - 25px);
		height: 45%;
		padding: 5px;

	}

	.banner-next-container-fix {
    	margin-top: -11%;
    }

    .back-privacy-policy-container {
    	bottom: 20px;
	    right: 15px;
	    transform: unset;
	}

     .back-icon {
		font-size: 1.2rem;
	}

	.back-container p {
		font-size: 0.9rem;
	}
 
	
}

@media screen and (max-width: 350px) 
{
	.logo-container img {
    	min-width: 80px;
    }
}

@media screen and (min-width: 1900px) 
{
	html {
		font-size: 16px;
	}

	.logo-first-container {
    	max-width: calc(100% - 250px);
	}

	.banner-container {
	    min-height: 750px;
	}

	.home-social-container img {
		width: 26px;
	}

}

@media screen and (min-width: 2100px) 
{
	html {
		font-size: 17px;
	}

	.logo-first-container {
    	max-width: calc(100% - 350px);
	}

	.home-social-container img {
		width: 27px;
	}

}


