.privacy-policy-container {
	margin-top: 75px;
	position: relative;
}

.privacy-policy-item-container {
	margin-top: 40px;
}

.privacy-policy-item-container h2 {
	font-family: didot-bold;
	font-size: 2rem;
}

.privacy-policy-item-container p {
	margin-top: 10px;
}

.privacy-policy-item-container p a {
	text-decoration: none;
	color: #3C3C3B;
	font-weight: bold;
}

.privacy-policy-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 75px;
	margin-bottom: 40px;
}

.privacy-policy-footer p {
	font-size: 0.95rem;
}

.privacy-policy-footer p a {
	text-decoration: none;
	color: #3C3C3B;
}

.back-privacy-policy-container {
	position: fixed;
    bottom: 15px;
    right: 5vw;
    transform: translateX(50%);
}

.back-container {
	display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.back-icon {
	font-size: 1.2rem;
	color: #1D509B;
}

.back-container p {
	font-size: 0.95rem;
	color: #1D509B;
}